
import { Container, Navbar } from 'react-bootstrap'

function Nav() {
  return (
    <Navbar >
      <Container>
        <Navbar.Brand>The Weather App</Navbar.Brand>
      </Container>
    </Navbar>
  )
}

export default Nav